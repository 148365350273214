// src/utils/s3Utils.ts
import { GetObjectCommand, ListObjectsV2Command } from '@aws-sdk/client-s3';
import { s3Client } from './s3Client';
import { stage } from './constants';

export const listOpenApiFiles = async () => {
  const command = new ListObjectsV2Command({
    Bucket: `${stage}-api-doc-ei-tonies-vue-app`,
    Prefix: '',
  });

  const response = await s3Client.send(command);

  const content = response.Contents?.filter((file) => file.Key?.endsWith('.json')) || [];

  return content;
};

export const getOpenApiJson = async (key: string) => {
  const command = new GetObjectCommand({
    Bucket: `${stage}-api-doc-ei-tonies-vue-app`,
    Key: key,
  });

  try {
    const response = await s3Client.send(command);
    const str = await response.Body?.transformToString();
    if (str) {
      const parsedJsonApi = JSON.parse(str);

      console.log('parsedJsonApi', parsedJsonApi);
      return parsedJsonApi;
    } else {
      throw new Error('s3 bucket item is undefined');
    }
  } catch (err) {
    console.error(err);
  }
};
