// src/router/index.ts
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from './components/Home.vue';
import OpenApiList from './components/OpenApiList-Item.vue';
import SwaggerUIComponent from './components/Swagger-UI.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'API Docs' },
  },
  {
    path: '/openapi-list',
    name: 'OpenApiList',
    component: OpenApiList,
    meta: { title: 'OpenApiList' },
  },
  {
    path: '/:fileKey',
    name: 'SwaggerUI',
    meta: { title: 'SwaggerUI' },
    component: SwaggerUIComponent,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
