import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Browse OpenAPI Documentation", -1)),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file) => {
        return (_openBlock(), _createElementBlock("li", {
          key: file.Key
        }, [
          _createVNode(_component_router_link, {
            to: { name: 'SwaggerUI', params: { fileKey: file.Key } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.stripExtension(file.Key)), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ]))
}