import { identityPoolId, stage } from './utils/constants';

// src/aws-exports.js
const awsConfig = {
  Auth: {
    Cognito: {
      identityPoolId: identityPoolId,
      region: 'eu-central-1',
    },
  },
  Storage: {
    S3: {
      bucket: `${stage}-api-doc-ei-tonies-vue-app`,
      region: 'eu-central-1',
    },
  },
};

export default awsConfig;
