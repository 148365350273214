export const stage = process.env.VUE_APP_STAGE;

if (!stage) {
  throw new Error('stage is undefined');
}

// This is optained at prebuild step, in package.json
export const identityPoolId = process.env.VUE_APP_POOL_ID || '';
if (!identityPoolId) {
  throw new Error('identityPoolId is undefined');
}
